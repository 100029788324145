import { GetCamRecognitionsRecognition } from '../api/api-sdk';
import { ICameraRecognitionConfig, IDevice } from './dashboard';

export enum CameraViewType {
  TemperaturePlus = 'temperature+',
  StomataPlus = 'stomata+',
}

export interface ICameraImage {
  id: number;
  camera: IDevice;
  viewType: CameraViewType;
  imageUrl: string;
  timestamp: string;
  recognitionConfig?: ICameraRecognitionConfig[];
  recognitionData?: GetCamRecognitionsRecognition;
}
