/* eslint-disable @ngrx/no-store-subscription */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Chart, ChartConfiguration } from 'chart.js';
import {
  CameraApi,
  DataApi,
  LocationChartAllVarsRequest,
} from '../api/api-sdk';
import { ISigrowLocation } from '../model/admin';
import { ISigrowChartConfiguration, SigrowDataset } from '../model/dashboard';
import { DateRange } from '../model/dateRange';
import { adminFeature } from '../state/admin/feature';

export class BaseDashboardService {
  protected cameraApi = inject(CameraApi);
  protected dataApi = inject(DataApi);
  protected store = inject(Store);

  protected timezone: string | undefined;

  // TODO: refactor this
  protected colorsMap = new Map<string, string>();

  constructor() {
    Chart.defaults.font.family = 'Satoshi, sans-serif';
    // TODO: refactor this
    this.store
      .select(adminFeature.selectLocationTimezone)
      .subscribe((timezone) => (this.timezone = timezone));
  }

  loadReadings(activeLocation: ISigrowLocation, dateRange: DateRange) {
    const { date_begin, date_end } = this.getApiDateRange(dateRange);
    return this.dataApi.locationChartAllVarsCreate(
      activeLocation!.location!.central_id,
      new LocationChartAllVarsRequest({
        date_begin,
        date_end,
      }),
    );
  }

  protected getApiDateRange(
    dateRange: DateRange,
    apiTimestampFormat: string = 'YYYYMMDDHH',
  ) {
    return {
      date_begin: dateRange
        .start()
        .tz(this.timezone, !dateRange.applyStartTimezone)
        .format(apiTimestampFormat),
      date_end: dateRange
        .end()
        .tz(this.timezone, !dateRange.applyEndTimezone)
        .format(apiTimestampFormat),
    };
  }

  protected getEmptyChartData(): ISigrowChartConfiguration['data'] {
    return { datasets: [] };
  }

  protected getEmptyChartOptions(
    dateRange: DateRange,
  ): ChartConfiguration['options'] {
    const daysRange = dateRange.end().diff(dateRange.start(), 'days');

    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.05,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            tooltipFormat: 'dddd HH:mm:ss',
            unit: daysRange > 2 ? 'day' : 'hour',
            displayFormats: {
              hour: daysRange > 2 ? 'dd H:mm' : 'H:mm',
              day: 'MMM DD',
              week: 'MMM DD',
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
      interaction: {
        intersect: false,
        mode: 'x',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  }

  protected addAlpha(color: string, opacity: number) {
    const hexOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + hexOpacity.toString(16).toUpperCase();
  }

  protected assignDatasetColors(dataset: SigrowDataset) {
    const color = this.getDatasetColor(dataset);
    dataset.borderColor = (dataset as any).pointBackgroundColor = color;
  }

  protected getDatasetColor(dataset: SigrowDataset) {
    let color: string | undefined;

    const colorKey = `${dataset.deviceId}${dataset.label}${dataset.variableName}`;
    color = this.colorsMap.get(colorKey);
    if (!color) {
      color = this.chartColors[this.colorsMap.size];
      this.colorsMap.set(colorKey, color);
    }

    return color;
  }

  private readonly chartColors = [
    '#3F51B5',
    '#00BCD4',
    '#8BC34A',
    '#FFC107',
    '#795548',
    '#673AB7',
    '#F44336',
    '#03A9F4',
    '#4CAF50',
    '#FFEB3B',
    '#FF5722',
    '#E91E63',
    '#607D8B',
    '#2196F3',
    '#9C27B0',
    '#009688',
    '#CDDC39',
    '#FF9800',
    '#9E9E9E',

    'rgba(54,162,235,1)',
    'rgba(255,206,86,1)',
    'rgba(75,192,192,1)',
    'rgba(153,102,255,1)',
    'rgba(255,159,64,1)',
    'rgba(255,0,77,1)',
    'rgba(0,51,102,1)',
    'rgba(35,248,144,1)',
    'rgba(64,48,117,1)',
    'rgba(205,136,175,1)',
    'rgba(255,170,0,1)',
    'rgba(212,106,106,1)',
    'rgba(79,98,142,1)',
    'rgba(135,124,176,1)',
    'rgba(255,170,0,1)',
    'rgba(255,199,88,1)',
    'rgba(63,18,85,1)',
    'rgba(6,21,57,1)',
    'rgba(68,0,39,1)',
    'rgba(85,0,0,1)',
    'rgba(134,201,138,1)',
    'rgba(128,21,21,1)',
    'rgba(170,85,133,1)',
    'rgba(102,17,65,1)',
    'rgba(45,134,50,1)',
    'rgba(0,49,51,1)',
    'rgba(38,23,88,1)',
    'rgba(22,41,85,1)',
    'rgba(84,167,89,1)',
    'rgba(35,100,103,1)',
    'rgba(90,41,113,1)',
    'rgba(136,45,97,1)',
    'rgba(170,57,57,1)',
    'rgba(13,74,77,1)',
    'rgba(46,66,114,1)',
    'rgba(222,148,0,1)',
    'rgba(96,82,146,1)',
    'rgba(255,170,170,1)',
    'rgba(0,67,4,1)',
    'rgba(153,116,170,1)',
    'rgba(39,3,57,1)',
    'rgba(18,7,59,1)',
    'rgba(120,74,142,1)',
    'rgba(65,126,128,1)',
    'rgba(17,100,22,1)',
    'rgba(103,152,154,1)',
    'rgba(120,135,171,1)',
  ];
}
