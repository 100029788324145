import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { produce } from 'immer';
import _, { orderBy } from 'lodash';
import { INodesData } from '../../../../api/api-sdk';
import {
  INodeAttribute,
  INodeAttributesGroup,
  INodeAttributesGroupWithAlert,
  INodeListItem,
  IVariableAlert,
  NodeAttributeType,
} from '../../../../model/node';
import { AdminActions } from './../../../../state/admin/actions';

import { adminFeature } from '../../../../state/admin/feature';
import { StateUtils } from '../../../../utils/state';
import { NodesActions } from './nodes.actions';

export interface INodesState {
  attrGroups: INodeAttributesGroup[];
  nodesData: INodesData[];
  nodeList: INodeListItem[];
  variableAlerts: IVariableAlert[];
  readingColumns: ColDef[];
  activeNodeScrollingIndex: number;
  nodesSortField: { field: string; direction?: 'asc' | 'desc' };
  nodesOrder: number[];
}

export const initialState: INodesState = {
  attrGroups: [
    {
      type: NodeAttributeType.attribute,
      id: 'attributes',
      attributes: [
        {
          id: 'deviceId',
          valuePath: 'remote_id',
          title: 'Device ID',
          selected: false,
        },
        {
          id: 'deviceName',
          valuePath: 'node_name',
          title: 'Device Name',
          selected: false,
        },
      ],
    },
  ],
  nodesData: [],
  nodeList: [],
  variableAlerts: [],
  readingColumns: [],
  activeNodeScrollingIndex: -1,
  nodesSortField: { field: 'date' },
  nodesOrder: [],
};

export const reducer = createReducer(
  initialState,
  on(AdminActions.variablesUpdated, (state, action) =>
    produce(state, (draft) => {
      _.remove(draft.attrGroups, (g) => g.type === NodeAttributeType.variable);
      draft.attrGroups.push({
        type: NodeAttributeType.variable,
        id: 'variables',
        attributes: action.variables.map(
          (v) =>
            ({
              id: v.name,
              valuePath: v.name,
              title: v.full_name,
              selected: false,
              uom: v.unit,
              alertMinValue: v.min,
              alertMaxValue: v.max,
            }) as INodeAttribute,
        ),
      });
    }),
  ),
  on(AdminActions.variableConfigUpdated, (state, action) =>
    produce(state, (draft) => {
      const variableAttribute = draft.attrGroups
        .find((ag) => ag.id === 'variables')
        ?.attributes.find((a) => a.id === action.variable.name);
      if (variableAttribute) {
        variableAttribute.alertMinValue = action.variable.min;
        variableAttribute.alertMaxValue = action.variable.max;
      }
    }),
  ),
  on(NodesActions.toggleAttribute, (state, action) =>
    produce(state, (draft) => {
      const attribute = _(draft.attrGroups)
        .map((g) => g.attributes)
        .flatten()
        .find((a) => a.id === action.id);
      if (!attribute) {
        return;
      }
      attribute.selected = !attribute.selected;
    }),
  ),
  on(NodesActions.nodesDataUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.nodesData = _.cloneDeep(action.nodesData);
    }),
  ),
  on(NodesActions.nodesAndAlertsUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.nodeList = action.nodes;
      draft.variableAlerts = action.alerts;
    }),
  ),
  on(NodesActions.startActiveNodeScrolling, (state, action) =>
    produce(state, (draft) => {
      draft.activeNodeScrollingIndex = draft.nodeList.findIndex(
        (n) => n.id === action.nodeId,
      );
    }),
  ),
  on(NodesActions.scrollActiveNode, (state, action) =>
    produce(state, (draft) => {
      draft.activeNodeScrollingIndex += action.delta;
      if (draft.activeNodeScrollingIndex >= draft.nodeList.length) {
        draft.activeNodeScrollingIndex = 0;
      } else if (draft.activeNodeScrollingIndex < 0) {
        draft.activeNodeScrollingIndex = draft.nodeList.length - 1;
      }
    }),
  ),
  on(NodesActions.nodeUpdated, (state, action) =>
    produce(state, (draft) => {
      const node = draft.nodesData.find((n) => n.remote_id === action.nodeId);
      if (!node) {
        return;
      }
      node.node_name = action.form.name;
      node.visible = action.form.visible ? 1 : 0;
      node.physical_location = action.form.location;
      node.node_alerts.nodes_status_warning_alerts =
        action.form.statusWarningAlerts;
      node.node_alerts.nodes_offline_alerts = action.form.offlineAlerts;
    }),
  ),
  on(NodesActions.readingColumnsUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.readingColumns = action.columns;
    }),
  ),
  on(NodesActions.setNodesSortField, (state, action) =>
    produce(state, (draft) => {
      draft.nodesSortField = action.sortField;
    }),
  ),
  on(NodesActions.setCustomNodesOrder, (state, action) =>
    produce(state, (draft) => {
      draft.nodesOrder = action.nodesOrder;
    }),
  ),
  on(NodesActions.restoreCustomNodesOrder, (state, action) =>
    produce(state, (draft) => {
      draft.nodesOrder = action.nodesOrder;
      draft.nodesSortField = action.nodesSortField;
    }),
  ),
  on(NodesActions.nodeUpdated, (state, action) =>
    produce(state, (draft) => {
      StateUtils.updateArrayItem(
        draft.nodesData,
        (r) => r.remote_id === action.nodeId,
        { name: action.form.name },
      );
    }),
  ),
);

export const nodesFeature = createFeature({
  name: 'nodes',
  reducer,
  extraSelectors: ({
    selectNodesData,
    selectNodeList,
    selectActiveNodeScrollingIndex,
    selectAttrGroups,
    selectVariableAlerts,
    selectNodesSortField,
    selectNodesOrder,
  }) => ({
    selectNodesDataById: (id: number) =>
      createSelector(selectNodesData, (nodes) =>
        nodes.find((n) => n.remote_id === id),
      ),
    selectCurrentScrollingNode: createSelector(
      selectNodeList,
      selectNodesData,
      selectActiveNodeScrollingIndex,
      (nodesList, nodesData, activeNodeScrollingIndex) => ({
        nodeItem: nodesList[activeNodeScrollingIndex],
        nodeData: nodesData.find(
          (nd) => nd.remote_id === nodesList[activeNodeScrollingIndex]?.id,
        ),
      }),
    ),
    selectNodesDataWithLocalTime: createSelector(
      selectNodesData,
      adminFeature.selectLocationTimezone,
      (nodesData, timezone) =>
        nodesData.map(
          (n) =>
            ({
              ...n,
              date: dayjs
                .unix(n.timestamp_unix)
                .tz(timezone)
                .format('DD MMM YYYY, HH:mm'),
            }) satisfies INodesData,
        ),
    ),
    selectSortedNodesList: createSelector(
      selectNodeList,
      selectNodesSortField,
      selectNodesOrder,
      (nodes, sortField, order) =>
        orderBy(
          nodes,
          sortField.field === 'custom'
            ? (n) => order.findIndex((id) => id === n.id)
            : sortField.field,
          sortField.direction ?? 'asc',
        ),
    ),
    selectAttrsWithAlerts: createSelector(
      selectAttrGroups,
      selectVariableAlerts,
      (attrGroups, alerts) =>
        attrGroups.map(
          (ag) =>
            ({
              ...ag,
              attributes: ag.attributes.map((attr) => ({
                ...attr,
                alertType: alerts.find((alr) => alr.id === attr.id)?.alertType,
              })),
            }) satisfies INodeAttributesGroupWithAlert,
        ),
    ),
  }),
});
