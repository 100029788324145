import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IGetCurrentCamPoint,
  IVariableToDisplay,
} from '../../../../api/api-sdk';
import { ICameraImage } from '../../../../model/camera';
import {
  ICameraRecognitionConfig,
  ICameraVariable,
  IChartUomConfig,
  IChatGenerationResult,
  ICustomPoint,
  IDevice,
  ISavedDashboardConfig,
  ITooltip,
} from '../../../../model/dashboard';
import { DateRange } from '../../../../model/dateRange';

export const DashboardActions = createActionGroup({
  source: 'Dashboard',
  events: {
    dateRangeChanged: props<{ dateRange: DateRange }>(),
    toggleDevice: props<{ device: IDevice }>(),
    toggleVariable: props<{ variable: IVariableToDisplay }>(),
    toggleRecognitionVariable: props<{ variable: ICameraVariable }>(),
    togglePointVariable: props<{ variable: ICameraVariable }>(),
    togglePoint: props<{ point: IGetCurrentCamPoint }>(),
    toggleCustomPoint: props<{ customPoint: ICustomPoint }>(),
    toggleRecognition: props<{ config: ICameraRecognitionConfig }>(),
    focusDevice: props<{
      device: IDevice;
      variables: IVariableToDisplay[];
    }>(),
    chartHover: props<{
      timestamp: number | undefined;
      clientX: number;
      clientY: number;
    }>(),
    refresh: emptyProps(),
    regenerateChart: emptyProps(),
    regenerateChartSuccess: props<{ result: IChatGenerationResult }>(),
    refreshCameraImages: emptyProps(),
    refreshCameraImagesSuccess: props<{ images: ICameraImage[] }>(),
    toggleImagesDisplay: emptyProps(),
    cacheUpdated: props<{ key: string; value: unknown }>(),
    chartUomConfigChanged: props<{ uom: string; config: IChartUomConfig }>(),
    saveConfig: props<{ name: string; locationId: number }>(),
    restoreSavedConfigs: props<{ savedConfigs: ISavedDashboardConfig[] }>(),
    applySavedConfig: props<{ savedConfig: ISavedDashboardConfig }>(),
    deleteSavedConfig: props<{ savedConfig: ISavedDashboardConfig }>(),
    setDefaultConfig: props<{ savedConfig: ISavedDashboardConfig }>(),
    unsetDefaultConfig: emptyProps(),
    updateCurrentTooltips: props<{ tooltips: ITooltip[] }>(),
    shiftDateRange: props<{ delta: number }>(),
  },
});
