import {
  IChartUomConfig,
  IDashboardConfig,
  IDevice,
  ISavedDashboardConfig,
  ITooltip,
  Selectable,
  emptyChartData,
  pointVariables,
  recognitionVariables,
} from './../../../../model/dashboard';
import { AdminActions } from './../../../../state/admin/actions';
import { adminFeature } from './../../../../state/admin/feature';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import dayjs from 'dayjs';
import { produce } from 'immer';
import _, { remove } from 'lodash';
import { nanoid } from 'nanoid';
import {
  IGetCurrentCamPoint,
  IVariableToDisplay,
} from '../../../../api/api-sdk';
import { ICameraImage } from '../../../../model/camera';
import { DateRange, dateRanges } from '../../../../model/dateRange';

import { DeviceUtils } from '../../../../utils/device';
import { StateUtils } from '../../../../utils/state';
import { NodesActions } from '../../nodes/state/nodes.actions';
import {
  DashboardCacheType,
  ICameraRecognitionConfig,
  ICameraVariable,
  IChartData,
} from './../../../../model/dashboard';
import { DashboardActions } from './dashboard.actions';

export interface IDashboardState extends IDashboardConfig {
  dateRange: DateRange;
  chartData: IChartData;
  chartColorsMap: Map<string, string>;
  chartUomConfigs: { [id: string]: IChartUomConfig };
  cameraImagesTimestamp: number;
  cameraImagesToDisplay: ICameraImage[];
  cache: Map<string, any>;
  savedConfigs: ISavedDashboardConfig[];
  currentTooltips: ITooltip[];
  imagesDisplayed: boolean;
}

export const initialState: IDashboardState = {
  dateRange: dateRanges[0],
  devices: [],
  variables: [],
  recognitionVariables: [],
  pointVariables: [],
  points: [],
  customPoints: [],
  recognitions: [],
  chartData: emptyChartData,
  chartColorsMap: new Map(),
  chartUomConfigs: {},
  cameraImagesTimestamp: 0,
  cameraImagesToDisplay: [],
  cache: new Map(),
  currentTooltips: [],
  imagesDisplayed: false,

  // TODO: move to it's own state
  savedConfigs: [],
};

export const reducer = createReducer(
  initialState,
  on(
    AdminActions.activeLocationChanged,
    (state): IDashboardState => ({
      ...initialState,
      savedConfigs: state.savedConfigs,
    }),
  ),
  on(
    DashboardActions.applySavedConfig,
    (state, action): IDashboardState => ({
      ...state,
      ...action.savedConfig.config,
    }),
  ),
  on(DashboardActions.regenerateChartSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.chartData = action.result.data as any;
      draft.chartColorsMap = action.result.colorsMap;
    }),
  ),
  on(DashboardActions.refreshCameraImagesSuccess, (state, action) =>
    produce(state, (draft) => {
      draft.cameraImagesToDisplay = action.images;
    }),
  ),
  on(DashboardActions.dateRangeChanged, (state, action) =>
    produce(state, (draft) => {
      draft.dateRange = action.dateRange;
    }),
  ),
  on(DashboardActions.toggleDevice, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.devices, action.device);
    }),
  ),
  on(DashboardActions.focusDevice, (state, action) =>
    produce(state, (draft) => {
      draft.devices = [action.device];
      draft.variables = [...action.variables];
    }),
  ),
  on(DashboardActions.toggleVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.variables, action.variable);
    }),
  ),
  on(DashboardActions.toggleRecognitionVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.recognitionVariables, action.variable);
    }),
  ),
  on(DashboardActions.togglePointVariable, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.pointVariables, action.variable);
    }),
  ),
  on(DashboardActions.togglePoint, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(
        draft.points,
        action.point,
        (p) => p.id === action.point.id,
      );
    }),
  ),
  on(DashboardActions.toggleCustomPoint, (state, action) =>
    produce(state, (draft) => {
      toggleArrayItem(draft.customPoints, action.customPoint);
    }),
  ),
  on(DashboardActions.toggleRecognition, (state, action) =>
    produce(state, (draft) => {
      const removed = _.remove(
        draft.recognitions,
        (r) =>
          r.cameraId === action.config.cameraId &&
          r.recognitionType === action.config.recognitionType,
      );
      if (!removed.length) {
        draft.recognitions.push(action.config);
      }
    }),
  ),
  on(DashboardActions.chartHover, (state, action) =>
    produce(state, (draft) => {
      draft.cameraImagesTimestamp = action.timestamp ?? 0;
    }),
  ),
  on(DashboardActions.cacheUpdated, (state, action) =>
    produce(state, (draft) => {
      draft.cache.set(action.key, action.value);
    }),
  ),
  on(DashboardActions.chartUomConfigChanged, (state, action) =>
    produce(state, (draft) => {
      if (
        _.isNumber(action.config.minValue) ||
        _.isNumber(action.config.maxValue)
      ) {
        draft.chartUomConfigs[action.uom] = action.config;
      } else {
        delete draft.chartUomConfigs[action.uom];
      }
    }),
  ),
  on(DashboardActions.saveConfig, (state, action) =>
    produce(state, (draft) => {
      draft.savedConfigs.push({
        id: nanoid(),
        name: action.name,
        locationId: action.locationId,
        dateRangeName: state.dateRange.name,
        default: false,
        config: {
          customPoints: state.customPoints,
          devices: state.devices,
          points: state.points,
          recognitions: state.recognitions,
          recognitionVariables: state.recognitionVariables,
          pointVariables: state.pointVariables,
          variables: state.variables,
        },
      });
    }),
  ),
  on(DashboardActions.restoreSavedConfigs, (state, action) =>
    produce(state, (draft) => {
      draft.savedConfigs = action.savedConfigs;
    }),
  ),
  on(DashboardActions.deleteSavedConfig, (state, action) =>
    produce(state, (draft) => {
      remove(draft.savedConfigs, (c) => c.id === action.savedConfig.id);
    }),
  ),
  on(DashboardActions.setDefaultConfig, (state, action) =>
    produce(state, (draft) => {
      for (const config of draft.savedConfigs) {
        config.default = config.id === action.savedConfig.id;
      }
    }),
  ),
  on(DashboardActions.unsetDefaultConfig, (state) =>
    produce(state, (draft) => {
      const defaultConfig = draft.savedConfigs.find((c) => c.default);
      if (defaultConfig) {
        defaultConfig.default = false;
      }
    }),
  ),
  on(DashboardActions.updateCurrentTooltips, (state, action) =>
    produce(state, (draft) => {
      draft.currentTooltips = action.tooltips;
    }),
  ),
  on(DashboardActions.toggleImagesDisplay, (state, action) =>
    produce(state, (draft) => {
      draft.imagesDisplayed = !draft.imagesDisplayed;
    }),
  ),
  // TODO: refactor that and use pipe for device name everywhere
  on(NodesActions.nodeUpdated, (state, action) =>
    produce(state, (draft) => {
      StateUtils.updateArrayItem(
        draft.devices,
        (r) => r.remote_id === action.nodeId,
        { name: action.form.name },
      );
    }),
  ),
);

export const dashboardFeature = createFeature({
  name: 'dashboard',
  reducer,
  extraSelectors: ({
    selectChartData,
    selectCache,
    selectDevices,
    selectRecognitionVariables,
    selectPointVariables,
    selectRecognitions,
    selectCustomPoints,
    selectVariables,
    selectPoints,
    selectChartUomConfigs,
    selectSavedConfigs,
    selectCurrentTooltips,
    selectCameraImagesToDisplay,
  }) => ({
    selectChatJsBinding: createSelector(selectChartData, (chartData) =>
      _.cloneDeep(chartData),
    ),
    selectCacheByType: (type: DashboardCacheType) =>
      createSelector(selectCache, (cache) => cache.get(type)),
    selectRecognitionVariables: createSelector(
      adminFeature.selectAllDevices,
      selectDevices,
      (allDevices, selectedDevices) =>
        selectedDevices.some(
          (sd) =>
            !!allDevices.find((ad) => ad.remote_id === sd.remote_id)
              ?.flower_recognition,
        )
          ? recognitionVariables
          : [],
    ),
    selectPointVariables: createSelector(selectDevices, (devices) =>
      devices.some((d) => !!d.thermal_camera_id) ? pointVariables : [],
    ),
    selectDeviceCustomPoints: (deviceId: number) =>
      createSelector(selectCustomPoints, (points) =>
        points.filter((p) => p.cameraId === deviceId),
      ),
    selectChartUomConfigByName: (uom: string) =>
      createSelector(selectChartUomConfigs, (configs) => configs[uom]),
    selectDashboardDevices: createSelector(
      adminFeature.selectDevicesForActiveLocation,
      selectDevices,
      (devices, activeDevices) =>
        devices.map(
          (d) =>
            ({
              ...d,
              selected: activeDevices.some(
                (ad) =>
                  ad.remote_id === d.remote_id &&
                  ad.thermal_camera_id === d.thermal_camera_id,
              ),
            }) satisfies Selectable<IDevice>,
        ),
    ),
    selectSavedConfigsForActiveLocation: createSelector(
      adminFeature.selectActiveLocation,
      selectSavedConfigs,
      (activeLocation, configs) =>
        _(configs)
          .filter((c) => c.locationId === activeLocation?.location?.central_id)
          .orderBy((c) => c.default, 'desc')
          .value(),
    ),
    selectGroupedTooltips: createSelector(selectCurrentTooltips, (tooltips) =>
      _(tooltips)
        .groupBy((t) => `${t.title} • ${dayjs(t.x).format('HH:mm:ss')}`)
        .map((tooltips, title) => ({ title, tooltips }))
        .value(),
    ),
    selectCameraImagesGrouped: createSelector(
      selectCameraImagesToDisplay,
      (images) =>
        _(images)
          .groupBy((i) => i.camera.remote_id)
          .map((images, cameraId) => ({ cameraId, images }))
          .value(),
    ),
    // TODO: refactor this
    isDeviceSelected: (item: IDevice) =>
      createSelector(selectDevices, (items) =>
        items.some(
          (i) =>
            i.remote_id === item.remote_id &&
            i.thermal_camera_id === item.thermal_camera_id,
        ),
      ),
    isVarSelected: (item: IVariableToDisplay) =>
      createSelector(selectVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isRecVarSelected: (item: ICameraVariable) =>
      createSelector(selectRecognitionVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isPointVarSelected: (item: ICameraVariable) =>
      createSelector(selectPointVariables, (items) =>
        items.some((i) => i.name === item.name),
      ),
    isPointSelected: (item: IGetCurrentCamPoint) =>
      createSelector(selectPoints, (items) =>
        items.some((i) => i.id === item.id),
      ),
    isRecSelected: (item: ICameraRecognitionConfig) =>
      createSelector(selectRecognitions, (items) =>
        items.some(
          (i) =>
            i.cameraId === item.cameraId &&
            i.recognitionType === item.recognitionType,
        ),
      ),
    isAnyCameraSelected: createSelector(
      selectDevices,
      (devices) => DeviceUtils.applyCamerasFilter(devices).length > 0,
    ),
  }),
});

// TODO: make predicate mandatory and merge predicate with is[entity]Selected selectors
function toggleArrayItem<T>(
  array: T[],
  item: T,
  predicate?: (item: T) => boolean,
) {
  const removed = _.remove(
    array,
    predicate ? (i) => predicate(i) : (item as any),
  );
  if (!removed.length) {
    array.push(item);
  }
  return removed.length;
}
